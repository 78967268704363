import React, { useEffect, useRef, useState } from 'react';
import InputField from '../_shared/components/InputField';
import { Box, List, ListItem, Typography, Paper, ClickAwayListener } from '@mui/material';

type PlaceAutocompleteProps = {
  onPlaceSelect: (place: any) => void;
  value: string;
  onChange: (value: string) => void;
};

type HereSearchResult = {
  title: string;
  id: string;
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    state: string;
    county: string;
    city: string;
    postalCode: string;
  };
  position: {
    lat: number;
    lng: number;
  };
};

const HERE_API_KEY = 'pV8_ZikpPhitkwXACIvQihDu1BWULH-Atzs7S7jXVb8';

const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = ({
  onPlaceSelect,
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [predictions, setPredictions] = useState<HereSearchResult[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  let timeoutId: NodeJS.Timeout;

  const handleInputChange = (newValue: string) => {
    onChange(newValue);
    
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (newValue.length > 0) {
      timeoutId = setTimeout(() => {
        fetchPredictions(newValue);
      }, 300);
    } else {
      clearPredictions();
    }
  };

  const fetchPredictions = async (input: string) => {
    if (input.length < 3) return;

    try {
      setIsLoading(true);
      setError(null);

      const params = new URLSearchParams({
        q: input,
        apiKey: HERE_API_KEY,
        limit: '10',
        lang: 'en',
        resultType: 'address'
      });

      const response = await fetch(
        `https://geocode.search.hereapi.com/v1/geocode?${params.toString()}`,
        {
          headers: {
            'Accept': 'application/json'
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error_description || 'Failed to fetch suggestions');
      }

      const data = await response.json();
      
      if (data.items && data.items.length > 0) {
        const transformedResults = data.items.map((item: any) => ({
          title: item.title,
          id: item.id,
          address: {
            label: item.address.label,
            countryCode: item.address.countryCode,
            countryName: item.address.countryName,
            state: item.address.state,
            county: item.address.county,
            city: item.address.city,
            postalCode: item.address.postalCode
          },
          position: {
            lat: item.position.lat,
            lng: item.position.lng
          }
        }));

        setPredictions(transformedResults);
        setIsOpen(true);
      } else {
        clearPredictions();
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
      clearPredictions();
    } finally {
      setIsLoading(false);
    }
  };

  const clearPredictions = () => {
    setPredictions([]);
    setIsOpen(false);
  };

  const handlePredictionClick = (prediction: HereSearchResult) => {
    onChange(prediction.address.label);
    onPlaceSelect({
      formatted_address: prediction.address.label,
      geometry: {
        location: {
          lat: prediction.position.lat,
          lng: prediction.position.lng
        }
      },
      name: prediction.title,
      place_id: prediction.id
    });
    clearPredictions();
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const handleInputFocus = () => {
    if (value.length > 0) {
      fetchPredictions(value);
    }
  };

  // if (error) {
  //   return (
  //     <Typography color="error" variant="body2">{error}</Typography>
  //   );
  // }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative">
        <InputField
          ref={inputRef}
          label="Event Address"
          value={value}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required={true}
        />
        {isLoading && (
          <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
            Loading suggestions...
          </Typography>
        )}
        {isOpen && predictions.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              zIndex: 1000,
              width: '100%',
              maxHeight: 200,
              overflowY: 'auto',
              mt: 1,
            }}
          >
            <List>
              {predictions.map((prediction) => (
                <ListItem
                  key={prediction.id}
                  onClick={() => handlePredictionClick(prediction)}
                  sx={{ 
                    cursor: 'pointer', 
                    '&:hover': { bgcolor: 'action.hover' },
                    padding: '8px 16px',
                  }}
                >
                  <Typography>{prediction.address.label}</Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default PlaceAutocomplete;