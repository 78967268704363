import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import DataGridTable from "../_shared/components/datagridTable";
import { Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import TicketSummaryCustomToolbar from "./ticketSalesSummary";

const MyTicketSales: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 200 },
    { field: "ticket_name", headerName: "Ticket Name", width: 200 },
    { field: "ticket_id", headerName: "Ticket ID", width: 100 },
    { field: "user_name", headerName: "User Name", width: 200 },
    { field: "purchase_date", headerName: "Purchase Date", width: 200 },
    { field: "price", headerName: "Price", width: 120 },
    { field: "fee", headerName: "Fees", width: 120 },
    { field: "tax", headerName: "Tax", width: 120 },
    { field: "total", headerName: "Total", width: 120 },
    { field: "promo_code", headerName: "Promo Code", width: 120 },
    { field: "transaction_id", headerName: "Transaction ID", width: 200 },
  ];

  const [rows, setRows] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>(null);

  const location = useLocation();
  const { eventData } = location.state || { eventData: {} };

  const fetchData = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const response = await apiGetRequest(
          `${urls.myTickets}/${eventData.id}`,
          {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        if (response?.code === "00" && response?.data?.code === "00") {
          const formattedRows = response.data.data.map((sale: any, index: number) => {
            const price = parseFloat(sale.price);
            const fee = parseFloat(sale.fee);
            const tax = parseFloat(sale.tax);
            const total = price + fee + tax;

            return {
              id: index,
              event_name: eventData.event_name,
              ticket_name: sale.ticket_name,
              ticket_id: sale.ticket_id,
              user_name: sale.name,
              purchase_date: sale.date_created,
              price: `${sale.currency}${price.toFixed(2)}`,
              fee: `${sale.currency}${fee.toFixed(2)}`,
              tax: `${sale.currency}${tax.toFixed(2)}`,
              total: `${sale.currency}${total.toFixed(2)}`,
              promo_code: sale.promo_code || "N/A",
              transaction_id: sale.transaction_id,
            };
          });

          setRows(formattedRows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.clear();
        setUserDetails(null);
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    } else {
      setUserDetails(null);
    }
  }, [eventData.id, eventData.event_name]);

  useEffect(() => {
    fetchData();

    const subscribedEvent: any = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          fetchData();
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [fetchData]);

  return (
    <Container>
      <Typography variant="h4" sx={{ marginBottom: 10 }}>
        Event Sales Details
      </Typography>
      <Helmet>
        <title>Event Sales Details</title>
      </Helmet>

      {userDetails ? (
        <DataGridTable
          columns={columns}
          rows={rows}
          page="ticket_sales"
          slots={{
            toolbar: TicketSummaryCustomToolbar,
          }}
        />
      ) : (
        <div style={{ marginTop: "20vh" }}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyTicketSales;