import { Container } from "@mui/material";
import { Column, Row } from "../_shared/styledComponents";
import { useEffect, useState } from "react";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { Helmet } from "react-helmet";
import EventsListComponent from "./EventsListComponent";
import { StyledTextField } from "../Auth/AuthPage";
import SearchIcon from "../_shared/assets/svg/Search.svg";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import "react-multi-carousel/lib/styles.css";
import Categories from "./Categories";
import { BodyText2 } from "../_shared/styled";
import { useLocation, useNavigate } from "react-router-dom";

const EventsHome = ({ searchTerm }: { searchTerm: string }) => {
  const [events, setEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasInitialData, setHasInitialData] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const initfunction = async () => {
    const res = await apiGetRequest(urls.events);
    const featuredRes = await apiGetRequest(urls.featuredEvents);
    const categoriesRes = await apiGetRequest(urls.categories);

    if (res?.data != null) {
      setEvents(res.data);
      setHasInitialData(res.data.length > 0 || (featuredRes?.data?.length ?? 0) > 0);
    }
    if (featuredRes?.data != null) {
      const shuffled = (featuredRes?.data ?? []).slice(0, 4); // Create a copy of the array
      for (let i = shuffled.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[randomIndex]] = [
          shuffled[randomIndex],
          shuffled[i],
        ];
      }
      setFeaturedEvents(shuffled);
    }
    if (categoriesRes?.data != null) {
      setCategories(categoriesRes?.data);
    }
  };

  useEffect(() => {
    initfunction();
  }, []);

  useEffect(() => {
    setSubscribedEvent(
      EventRegister.addEventListener(EventsEnum.Loading, (data: boolean) => {
        setLoading(data);
      })
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);
  return (
    <Column spacing={50} sx={{ padding: "0.5rem" }}>
      <Helmet>
        <title>Qasa Events</title>
      </Helmet>

      {!loading && (
        <>
          {/* <div style={{padding: 0, paddingLeft:"0", paddingRight: 0, height:"200px", width:"100%",}}>
            <Categories categories={categories} />
          </div> */}
          {featuredEvents.length > 0 &&
            featuredEvents.filter((event: any) =>
              searchTerm.length < 1
                ? true
                : `${event.event_name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ).length > 0 && (
              <EventsListComponent
                events={featuredEvents.filter((event: any) =>
                  searchTerm.length < 1
                    ? true
                    : `${event.event_name}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                )}
                header={"FEATURED EVENTS"}
                type="home"
                showStartPrice={true}
              />
            )}
          {events.length > 0 &&
            events.filter((event: any) =>
              searchTerm.length < 1
                ? true
                : `${event.event_name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ).length > 0 && (
              <EventsListComponent
                events={events
                  .filter((e: any) => {
                    const eventDateCreated = new Date(e.date_created);
                    return (
                      eventDateCreated <
                      new Date(Date.now() - 24 * 60 * 60 * 1000)
                    );
                  })
                  .filter((event: any) =>
                    searchTerm.length < 1
                      ? true
                      : `${event.event_name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                  )}
                header={"NEWLY ADDED"}
                type="home"
                showStartPrice={true}
              />
            )}
          {featuredEvents.filter((event: any) =>
            searchTerm.length < 1
              ? true
              : `${event.event_name}`
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
          ).length < 1 &&
            events.filter((event: any) =>
              searchTerm.length < 1
                ? true
                : `${event.event_name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ).length < 1 && (
              <Row
                style={{ width: "100%", height: "500px" }}
                mainAxisAlignment="center"
              >
                <BodyText2 style={{ marginTop: "3rem" }}>
                  {searchTerm.length > 0
                    ? "Sorry, no results were found for your search"
                    : "Sorry, there are no open events at this time please try again later"}
                </BodyText2>
              </Row>
            )}
        </>
      )}
    </Column>
  );
};

export default EventsHome;