import { useState, useEffect, useCallback } from "react";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { Column, Container, Row } from "../_shared/styledComponents";
import { EventsEnum } from "../_shared/types";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import DataGridTable from "../_shared/components/datagridTable";
import { HeaderGreenTopBar, HeaderOption, HeaderText } from "../_shared/styled";
import CreatePromoCodePopup from "./addPromoCode";
import { Helmet } from "react-helmet";

interface PromoCode {
  id: number;
  code: string;
  date_added: string;
  discount_type: "percentage" | "flat_rate";
  discount_value: number;
  event_id: number;
  event_name: string;
  expiry_date: string;
  quantity: number;
  status: "Y" | "N";
}

interface PromoCodeResponse {
  code: string;
  data: PromoCode[];
  msg: string;
}

const PromoCodes = () => {
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  // const [eventDetails, setEventDetails] = useState<any>(null);
  const [addPromoCode, setAddPromoCode] = useState<any>(false);
  const [rows, setRows] = useState<PromoCode[]>([]);

  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 200 },
    { field: "code", headerName: "Promo Code", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 100 },
    { 
      field: "discount_type", 
      headerName: "Discount Type", 
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return params.value === "percentage" ? "Percentage" : "Flat Rate";
      }
    },
    { 
      field: "discount_value", 
      headerName: "Discount Value", 
      width: 150,
      valueFormatter: (params: any) => {
        if (!params.value) return '';
        const discountType = params.row?.discount_type;
        if (discountType === "percentage") {
          return `${params.value}%`;
        }
        return `$${params.value}`;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return params.value === "Y" ? "Active" : "Inactive";
      }
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      width: 200,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return new Date(params.value).toLocaleDateString();
      }
    }
  ];

  const initFunct = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const myPromoCodeRes = await apiGetRequest(`/events/promo-codes`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });
        console.log(myPromoCodeRes, "promo codes")
        if (myPromoCodeRes?.code === "00") {
          // Set rows directly from the API response
          setRows(myPromoCodeRes.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, false);
      }
    } else {
      setUserDetails(null);
      setRows([]);
    }
  }, []);

  useEffect(() => {
    initFunct();

    const checkLoginStatusListener = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          initFunct();
        } else {
          setUserDetails(null);
          setRows([]);
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(checkLoginStatusListener as string);
    };
  }, [initFunct]);

  return (
    <>
      <Helmet>
        <title>Promo Codes</title>
      </Helmet>
      <Row
        mainAxisAlignment="space-between"
        crossAxisAlignment="end"
        sx={{ marginTop: "3rem" }}
      >
        <Column>
          <HeaderGreenTopBar />
          <HeaderText>Promo Codes</HeaderText>
        </Column>

        {/* <Row
          crossAxisAlignment="center"
          spacing={10}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <HeaderOption
            onClick={() => {
              setAddPromoCode(true);
            }}
          >
            Create Promo Code
          </HeaderOption>
        </Row> */}
      </Row>
      <br />
      <Container>
        {userDetails ? (
          <DataGridTable
            columns={columns}
            rows={rows}
            clickable={true}
            page="promo_codes"
          />
        ) : (
          <div style={{ marginTop: "20vh" }}>
            <NoResults
              header={"Not Logged In!"}
              body={"Kindly login to view this page"}
            />
          </div>
        )}
      </Container>
      {/* <CreatePromoCodePopup
        open={addPromoCode}
        onClose={() => {
          setAddPromoCode(false);
        }}
        events={eventDetails}
      /> */}
    </>
  );
};

export default PromoCodes;