import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const footerLinks = [
    { text: 'Help', mobileText: 'Help', path: '/i/contact' },
    { text: 'Privacy Policy', mobileText: 'Privacy Policy', path: '/i/privacy-policy' },
    { text: 'Terms of service', mobileText: 'Terms', path: '/i/terms-of-service' },
    { text: 'Downloads', mobileText: 'Downloads', path: '/i/downloads' },
    { text: 'FAQs', mobileText: 'FAQs', path: '/i/faq' },
    { text: 'Qasa Home', mobileText: 'Home', path: '/i' }
  ];

  return (
    <>
      <style>
        {`
          @media (max-width: 480px) {
            .footer-link-text-desktop {
              display: none;
            }
            .footer-link-text-mobile {
              display: inline;
            }
            .footer-links-container {
              justify-content: center !important;
            }
          }
          @media (min-width: 481px) {
            .footer-link-text-desktop {
              display: inline;
            }
            .footer-link-text-mobile {
              display: none;
            }
            .footer-links-container {
              justify-content: center !important;
            }
          }
        `}
      </style>
      
      <div style={{
        width: '100%',
        borderTop: '1px solid rgba(89, 95, 103, 0.1)',
        padding: '24px 0',
        backgroundColor: '#ffffff',
        boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.02)',
      }}>
        <div 
          className="footer-links-container"
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            gap: '8px',
            width: '100%',
            padding: '0 24px',
            boxSizing: 'border-box',
            maxWidth: '1200px',
            margin: '0 auto'
          }}>
          {footerLinks.map((link, index) => (
            <div
              key={index}
              onClick={() => navigate(link.path)}
              style={{
                display: 'inline-block',
                padding: '10px 16px',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#4A5568',
                fontFamily: 'Inter, system-ui, sans-serif',
                whiteSpace: 'nowrap',
                borderRight: index !== footerLinks.length - 1 ? '1px solid rgba(74, 85, 104, 0.1)' : 'none',
                transition: 'all 0.3s ease',
                position: 'relative',
                textDecoration: 'none',
                letterSpacing: '0.2px',
                fontWeight: '500'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = '#2D3748';
                e.currentTarget.style.backgroundColor = 'rgba(237, 242, 247, 0.7)';
                e.currentTarget.style.transform = 'translateY(-1px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = '#4A5568';
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <span className="footer-link-text-desktop">{link.text}</span>
              <span className="footer-link-text-mobile">{link.mobileText}</span>
            </div>
          ))}
        </div>
        <div style={{
          textAlign: 'center',
          marginTop: '20px',
          fontSize: '13px',
          color: '#718096',
          fontFamily: 'Inter, system-ui, sans-serif'
        }}>
          © {new Date().getFullYear()} Qasa. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;